@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;1,800&display=swap);
html,
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Lato",
    "Open Sans", sans-serif;
  background-color: white;
  margin: 0px;
  padding: 0px;
  width: 100vw;
}

.App {
  width: 100vw;
  background-color: white;
  justify-content: center;
}

.topbarContainer {
  right: 0px;
  left: 0px;
  background-color: #151515;
  top: 0;
  height: 64px;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
}

.topbarContainer2 {
  right: 0px;
  left: 0px;
  background-color: white;
  top: 0;
  height: 64px;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
}

.innerTopbar {
  height: 64px;
  flex-direction: row;
  display: flex;
  align-items: center;
  max-width: 1200px;
  width: 100%;
}

.TouchableLogoTopbar {
  height: 100%;
  display: flex;
  align-items: center;
}

.contactBtnContainer {
  height: 100%;
  width: 100%;
}

.bottomBarContainer {
  height: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 40px;
  /* padding-bottom: 50px; */
  /* margin-top: 100px; */
  border-top: 1px solid #dddddd;
  display: flex;
  align-self: center;
}

@media screen and (min-width: 900px) {
  .bottomBarContainer {
    flex-direction: row;
    justify-content: space-around;
  }
}

.firstScreenContainer {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.outer2 {
  background-color: #151515;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.secondScreenContainer {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  align-items: center;
  padding-top: 50px;
  justify-content: center;
  display: flex;
  max-width: 1200px;
}

.secondScreenContainer2 {
  background-color: #151515;
  padding-bottom: 100px;
}

.ThirdScreenContainer {
  background-color: rgba(29, 29, 32, 1);
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.inner5 {
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #f3f3f3;
}

.inner2reverse {
  max-width: 1200px;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  /* padding-top: 50px;
  padding-bottom: 50px; */
  background-color: white;
}

@media screen and (min-width: 900px) {
  .inner2reverse {
    flex-direction: row-reverse;
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.inner2 {
  /* width: 100%; */
  max-width: 1200px;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  align-self: center;
  /* padding-top: 50px;
  padding-bottom: 50px; */
  background-color: #151515;
}

@media screen and (min-width: 900px) {
  .inner2 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.titleContainer {
  /* align-items: center;
  justify-content: center; */
  padding-left: 20px;
  padding-right: 20px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  /* width: '50%'; */
  flex-wrap: wrap;
  max-width: 600px;
}

.test {
  display: flex;
  flex-wrap: wrap;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: row;
}

.inner1 {
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #151515;
  padding-top: 100px;
  padding-bottom: 100px;
}

.title1 {
  color: white;
  font-size: 2.5em;
  font-weight: 800;
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (min-width: 900px) {
  .title1 {
    font-size: 3.5em;
  }
}

.description1 {
  color: white;
  font-size: 1em;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  word-wrap: break-word;
  /* word-break: break-all; */
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}

@media screen and (min-width: 900px) {
  .description1 {
    font-size: 1.5em;
    margin-top: 30px;
  }
}

.title2 {
  /* color: black; */
  background: linear-gradient(to right, #f11953,
      #ee44e5, #8244ee, #4f44ee);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-weight: 800;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  padding: 0px;
  font-size: 4em;
  word-wrap: initial;
  word-break: keep-all;
}

@media screen and (min-width: 900px) {
  .title2 {
    font-size: 4em;
  }
}

.title4 {
  /* color: black; */
  background: linear-gradient(to right, #8244ee,
      #448bee, #44cfee, #44ee85);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-weight: 800;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  padding: 0px;
  font-size: 4em;
  word-wrap: initial;
  word-break: keep-all;
}

@media screen and (min-width: 900px) {
  .title4 {
    font-size: 4em;
  }
}

.title5 {
  /* color: black; */
  background: linear-gradient(to right, #44cfee,
      #44ee85, #b8ee44, #eea744);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-weight: 800;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  padding: 0px;
  font-size: 4em;
  word-wrap: initial;
  word-break: keep-all;
}

@media screen and (min-width: 900px) {
  .title5 {
    font-size: 4em;
  }
}

.description2 {
  color: #1f1f1f;
  /* color: white; */
  font-size: 1em;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  word-wrap: break-word;
  padding: 0px;
  /* word-break: break-all; */
  /* padding-left: 20px;
  padding-right: 20px; */
  /* text-align: center; */
}

@media screen and (min-width: 900px) {
  .description2 {
    font-size: 1.5em;
  }
}

.description3 {
  color: #ffffff;
  /* color: white; */
  font-size: 1em;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  word-wrap: break-word;
  padding: 0px;
  /* word-break: break-all; */
  /* padding-left: 20px;
  padding-right: 20px; */
  /* text-align: center; */
}

@media screen and (min-width: 900px) {
  .description3 {
    font-size: 1.5em;
  }
}

.screenshotContainer {
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
}

.screenshot1 {
  /* max-height: 450px; */
  max-width: 25em;
  /* max-height: 10%; */
  /* height: 50%; */
  /* width: 50%; */
  object-fit: contain;
  /* display: flex; */
  /* margin: 20px; */
  /* position: relative; */
  /* border-radius: 10px; */
  /* box-shadow: 0px 0px 30px 15px rgba(0, 0, 0, 0.2); */
}

@media screen and (min-width: 900px) {
  .screenshot1 {
    max-width: 35em;
  }
}

@media screen and (max-width: 400px) {
  .screenshot1 {
    max-width: 19em;
  }
}

.screenshot2 {
  /* max-height: 450px; */
  max-width: 15em;
  /* max-height: 10%; */
  /* height: 50%; */
  /* width: 50%; */
  object-fit: contain;
  /* display: flex; */
  /* margin: 20px; */
  /* position: relative; */
  /* border-radius: 10px; */
  /* box-shadow: 0px 0px 30px 15px rgba(0, 0, 0, 0.2); */
}

@media screen and (min-width: 900px) {
  .screenshot2 {
    max-width: 20em;
  }
}

@media screen and (max-width: 400px) {
  .screenshot2 {
    max-width: 100em;
  }
}

.topbarLogo {
  object-fit: contain;
  height: 50%;
  width: auto;
  vertical-align: middle;
}

.topbarLogo2 {
  object-fit: contain;
  height: 20%;
  /* width: 10%; */
  vertical-align: middle;
}

.termsBtn {
  border-width: 0px;
  padding: 0px;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  margin-bottom: 5px;
  /* margin-right: 5px;
  margin-left: 5px; */
  color: rgb(255, 255, 255);
  display: inline-flex;
  text-decoration: none;
}

.privacyPolicyBtn {
  border-width: 0px;
  padding: 0px;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  margin-bottom: 5px;
  /* margin-right: 5px; */
  /* margin-left: 5px; */
  color: rgb(255, 255, 255);
  display: inline-flex;
  text-decoration: none;
}

/* .contactBtn {
  background-color: #e8e8e8;
  color: black;
  width: auto;
  height: 40px;
  border-radius: 20px;
  border-width: 0px;
  font-size: 14px;
  font-family: "Open Sans";
  padding-left: 15px;
  padding-right: 15px;
  align-items: center;
  justify-content: center;
  align-content: center;
  display: inline-flex;
  text-align: center;
  text-decoration: none;
} */

.contactBtn {
  color: white;
  width: auto;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  align-content: center;
  display: inline-flex;
  text-align: center;
  text-decoration: none;
}

.copyright {
  color: rgb(255, 255, 255);
  vertical-align: middle;
  margin-right: 5px;
  margin-left: 5px;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}

.badgeContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 10px;
}

.badgeContainer2 {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 10px;
}

.AppstoreBadge {
  object-fit: contain;
  height: 40px;
  width: auto;
  margin-left: 2.5px;
  margin-right: 2.5px;
}

.GooglePlayBadge {
  object-fit: contain;
  height: 40px;
  width: auto;
  margin-left: 2.5px;
  margin-right: 2.5px;
}

.AppstoreBadge2 {
  object-fit: contain;
  height: 40px;
  width: auto;
  margin-right: 2.5px;
}

@media screen and (min-width: 900px) {
  .AppstoreBadge2 {
    margin-left: 10px;
  }
}

.GooglePlayBadge2 {
  object-fit: contain;
  height: 40px;
  width: auto;
}


.appLogo {
  object-fit: contain;
  height: 150px;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  display: block;
  align-self: center;
}

@media screen and (min-width: 900px) {
  .appLogo {
    height: 200px;
  }
}

.appLogo2 {
  object-fit: contain;
  height: 2.3em;
  width: 10em;
  /* margin-left: auto;
  margin-right: auto; */
  display: block;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 0px;
  /* align-self: center; */
}

@media screen and (min-width: 900px) {
  .appLogo2 {
    height: 3em;
  }
}

.storeLink {
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  padding: 0px;
  margin: 0px;
}

.title3 {
  color: black;
  font-size: 27px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
}

.inner4 {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.verifiedLogo {
  object-fit: contain;
  width: 10em;
  vertical-align: middle;
  margin-top: 50px;
  margin-bottom: 50px;
}

.verifiedTitle {
  color: black;
  font-size: 25px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  border-width: medium;
  margin-top: 0px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 0px;
  align-self: center;
  text-align: center;
}

.verifiedText {
  color: black;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 0px;
  margin-top: 5px;
  margin-left: 20px;
  margin-right: 20px;
}

.GreyLogo {
  object-fit: contain;
  height: 20px;
  width: auto;
  margin-top: 100px;
  margin-bottom: 0px;
}

.verifiedTeamBlizzup {
  color: rgb(156, 156, 156);
  font-size: 12px;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 0px;
  margin-top: 5px;
  margin-left: 20px;
  margin-right: 20px;
}

@media screen and (max-width: 2000px) and (min-width: 270px) {
  .inner3 {
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: column;
    display: flex;
  }
}

/* .AsHeading {
  color: #8d8d90;
  font-size: 22px;
  font-weight: 900;
  font-family: "Lato Black";
  border-width: medium;
  margin-top: 30px;
  padding: 0px;
} */

.emailHeading {
  color: #1f1f1f;
  font-size: 17px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  word-wrap: break-word;
  word-break: break-all;
  margin-top: 20px;
}

.nameHeading {
  color: #1f1f1f;
  font-size: 17px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  word-wrap: break-word;
  word-break: break-word;
  margin-top: 20px;
}

/* .IssueHeading {
  color: #8d8d90;
  font-size: 22px;
  font-weight: 900;
  font-family: "Lato Black";
  border-width: medium;
  margin-top: 30px;
  padding: 0px;
} */

.descriptionHeading {
  color: #1f1f1f;
  font-size: 17px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  word-wrap: break-word;
  word-break: break-all;
  margin-top: 20px;
}

.inputEmail {
  background-color: #e8e8e8;
  border-radius: 10px;
  border-width: 0px;
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
  color: black;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  outline: none;
}

.inputName {
  background-color: #e8e8e8;
  border-radius: 10px;
  border-width: 0px;
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
  color: black;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  outline: none;
}

.inputDescription {
  background-color: #e8e8e8;
  border-radius: 10px;
  border-width: 0px;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
  height: 150px;
  resize: none;
  /* margin-top: 10px; */
  color: black;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  outline: none;
}

.descriptionInfo {
  color: #8d8d90;
  font-size: 12px;
  font-family: "Lato";
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  font-weight: lighter;
  margin-bottom: 0px;
  margin-top: 5px;
  margin-left: 0px;
  margin-right: 0px;
}

.passwordError {
  color: red;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  font-weight: lighter;
  margin-bottom: 0px;
  margin-top: 5px;
  margin-left: 0px;
  margin-right: 0px;
}

.btn {
  border: none;
  background-color: #fe1353;
  border-radius: 10px;
  width: 200px;
  margin-top: 100px;
  margin-bottom: 80px;
  align-self: center;
  display: inline-block;
  height: 45px;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  font-weight: lighter;
  cursor: pointer;
  color: white;
  outline: none;
}

/* .AsBtnContainer {
  display: flex;
  flex-direction: row;
}



/* On mouse-over */
.btn:hover {
  background: #e6114a;
  color: white;
}

.submit {
  color: white;
}

/* .nonuser {color: white;}
.bug {color: white;}
.feedback {color: white;}
.safetyconcern {color: white;}
.deletemyaccount {color: white;}
.personaldata {color: white;}
.other {color: white;} */

/* .IssueBtnContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
} */

.termsTitle {
  color: black;
  font-size: 28px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  border-width: medium;
  margin-bottom: 0px;
  margin-top: 50px;
  padding: 0px;
  align-self: center;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
}

.termsContainer {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.innerTerms {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 30px;
  padding-bottom: 50px;
  flex-direction: column;
  display: flex;
}

@media screen and (min-width: 900px) {
  .innerTerms {
    max-width: 1200px;
  }
}

.termsHeading {
  color: black;
  font-size: 22px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  border-width: medium;
  padding: 0px;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 0px;
  margin-right: 0px;
}

.termsHeading2 {
  color: black;
  font-size: 18px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  border-width: medium;
  padding: 0px;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 0px;
  margin-right: 0px;
}

.termsText {
  color: rgba(0, 0, 0, 0.5);
  line-height: 25px;
  font-weight: lighter;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  border-width: medium;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  padding: 0px;
}

.stripeText {
  color: rgb(0, 94, 235);
  line-height: 25px;
  font-weight: lighter;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  border-width: medium;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  padding: 0px;
}

.info1 {
  color: black;
  font-weight: 500;
  font-size: 20px;
  font-family: 'Open Sans', sans-serif;
  margin-top: 20px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  padding: 0px;
  text-align: center;
}

.info2 {
  color: white;
  font-size: 13px;
  margin-bottom: 0px;
  margin-top: 10px;
  margin-left: 0px;
  margin-right: 0px;
  padding: 0px;
  text-align: center;
  font-style: italic;
}

.info3 {
  /* color: rgba(0, 0, 0, 0.5); */
  color: white;
  font-weight: lighter;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  border-width: medium;
  margin-top: 0px;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  max-width: 900px;
}

.helpContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.innerHelp {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 30px;
  padding-bottom: 50px;
  flex-direction: column;
  display: flex;
  width: 80%;
  flex-wrap: wrap;
}

@media screen and (min-width: 900px) {
  .innerHelp {
    max-width: 1200px;
  }
}

.helpHeading {
  color: black;
  font-size: 18px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  border-width: medium;
  padding: 0px;
  margin-top: 0px;
  margin-bottom: 5px;
  margin-left: 0px;
  margin-right: 0px;
  text-decoration: none;
}

.linkContainer {
  display: flex;
  flex-direction: column;
  border-width: 1px;
  border-color: #afafaf;
  border-radius: 10px;
  border-style: solid;
  padding: 20px;
  margin-bottom: 10px;
}

.link {
  /* font-size: 1em; */
  color: black;
  /* font-family: "Open Sans";
  font-weight: 800; */
}

.link2 {
  text-decoration: none;
  width: 250px;
  height: 60px;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.helpTitle {
  color: black;
  font-size: 28px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  border-width: medium;
  margin-bottom: 50px;
  margin-top: 20px;
  padding: 0px;
  align-self: center;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
}

.grayLogo {
  object-fit: contain;
  width: 2.5em;
  /* vertical-align: middle; */
  margin: 20px;
  left: 0;
}

.joinAppContainer {
  right: 0px;
  left: 0px;
  /* background-color: #fe1352ce; */
  background-color: #fe1352;
  bottom: 0;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  z-index: 2000;
  position: fixed;
  box-shadow: 20px 20px 20px 20px rgba(0, 0, 0, 0.329);
}

.joinBtn {
  background-color: white;
  color: black;
  width: 230px;
  height: 60px;
  border-radius: 30px;
  border-width: 0px;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
  padding-left: 25px;
  padding-right: 25px;
  align-items: center;
  justify-content: center;
  align-content: center;
  display: inline-flex;
  text-align: center;
  text-decoration: none;
}

.bottomBarCategoryContainer {
  height: auto;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
  align-content: center;
  /* padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
  padding-bottom: 20px; */
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 30px;
  display: flex;
}

@media screen and (min-width: 900px) {
  .bottomBarCategoryContainer {
    margin-left: 60px;
    margin-right: 0px;
    margin-top: 0px;
  }
}

.bottomBarCategoryContainer2 {
  height: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  display: flex;
  padding-bottom: 20px;
  border-bottom: 1px solid #dddddd;
}

@media screen and (min-width: 900px) {
  .bottomBarCategoryContainer2 {
    align-items: flex-start;
    justify-content: flex-start;
    align-content: flex-start;
    padding-bottom: 0px;
    border-bottom: 0px solid #dddddd00;
  }
}

.bottomBarCategoryTitle {
  /* border-width: 0px;
  padding: 0px; */
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
  padding: 0px;
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 5px;
  /* margin-right: 5px;
  margin-left: 5px; */
  color: black;
  /* display: inline-flex;
  text-decoration: none; */
}

.categoryText {
  border-width: 0px;
  padding: 0px;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  margin-bottom: 5px;
  /* margin-right: 5px;
  margin-left: 5px; */
  color: black;
  display: inline-flex;
  text-decoration: none;
}

.bottomBarCategoryContainerOuter {
  flex-direction: row;
  display: flex;
  /* margin: 20px; */
  justify-content: space-around;
}

.socialLinkIcon {
  object-fit: contain;
  height: 15px;
  width: auto;
  margin-right: 15px;
}

.bottomBarCategoryContainerSocial {
  margin-top: 15px;
}

.topTitleContainer {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  background-color: #151515;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.topTitle {
  color: white;
  font-size: 28px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  border-width: medium;
  margin-bottom: 20px;
  margin-top: 0px;
  padding: 0px;
  align-self: center;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
}

.copyrightContainer {
  width: 100%;
  display: flex;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #dddddd;
  padding-bottom: 130px;
  align-items: center;
  justify-content: center;
}

.copyright {
  font-size: 13px;
  font-family: 'Open Sans', sans-serif;
  color: black;
  text-align: center;
}

.contactContainer {
  align-items: center;
  /* justify-content: center; */
  display: flex;
  flex-direction: column;
  background-color: #151515;
  height: 100vh;
}

.contactHeading {
  color: white;
  font-size: 22px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  border-width: medium;
  padding: 0px;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 0px;
  margin-right: 0px;
}

.mail-no-underline:link {
  -webkit-text-decoration-color: none;
          text-decoration-color: none;
}

.mail-no-underline:visited {

  -webkit-text-decoration-color: none;

          text-decoration-color: none;
}

.mail-no-underline:hover {
  -webkit-text-decoration-color: none;
          text-decoration-color: none;
}

.mail-no-underline:active {
  -webkit-text-decoration-color: none;
          text-decoration-color: none;
}

td,
th {
  border: 1px solid rgb(234, 234, 234);
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
  padding-bottom: 10px;

}

td {
  text-align: center;
}

tr:nth-child(even) {
  background-color: rgb(255, 255, 255);
}

th[scope="col"] {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
}

th[scope="row"] {
  background-color: #ffffff;
}

caption {
  padding: 10px;
  caption-side: bottom;
}

table {
  border-collapse: collapse;
  border: 1px solid rgb(226, 226, 226);
  letter-spacing: 1px;
  font-family: sans-serif;
  font-size: .8rem;
  margin-top: 20px;
  margin-bottom: 20px;
}

.download_container {
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #151515;
  padding-top: 100px;
  padding-bottom: 100px;
}
